
        // class-names-loader generated code for /Users/asteponavicius/dev/rusys/src/ui/Dropdown.less
        import classNames from "!classnames/bind";
        import * as locals from "!!../../node_modules/.pnpm/mini-css-extract-plugin@2.9.0_webpack@5.91.0/node_modules/mini-css-extract-plugin/dist/loader.js!../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.91.0/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[2]!../../node_modules/.pnpm/postcss-loader@8.1.1_postcss@8.4.38_typescript@5.4.5_webpack@5.91.0/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[3].use[3]!../../node_modules/.pnpm/less-loader@12.2.0_less@4.2.0_webpack@5.91.0/node_modules/less-loader/dist/cjs.js!../../node_modules/.pnpm/less-import-once@1.1.4/node_modules/less-import-once/index.js!./Dropdown.less";
        import assign from "!../../node_modules/.pnpm/@ecomfe+class-names-loader@2.0.0_webpack@5.91.0/node_modules/@ecomfe/class-names-loader/dist/assign.js";

        var css = classNames.bind(locals);
        assign(locals, css);

        export default css;
    