// extracted by mini-css-extract-plugin
var _1 = "Z1zpIR2mPmSsmjakqx1k";
var _2 = "YpdEPzzY72Lj8xo4fOwG";
var _3 = "fFOPOoP2UkPPrK9jUc2q";
var _4 = "RDxWD2Dsc6_U_Nl9sQIM";
var _5 = "GkzVghoXgIAygGAMwyb5";
var _6 = "LzUyS9JOTvGkwWgmb0c8";
var _7 = "o1l8KEMNeRO1idZt4LeA";
var _8 = "Er9YunD5QG7ISRRMot90";
export { _1 as "HeadRow", _2 as "Row", _3 as "Table", _4 as "ValueCell", _5 as "empty", _6 as "name", _7 as "value", _8 as "year" }
