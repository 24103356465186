// extracted by mini-css-extract-plugin
var _1 = "hiaj76T2SAVD_ZtOyoP9";
var _2 = "yVcKQlqZTwpyM9P1nj86";
var _3 = "Bc5SYhVCCOHfhdYq3xdE";
var _4 = "gjPnM1qmujg3_uiLLm0a";
var _5 = "LJerdDtytpIMHhSHiCdw";
var _6 = "XLCdjQgWhBDOql8htV9j";
var _7 = "zTmauMe1wrCBttXVNrZg";
var _8 = "yESoBiOFZ2pDposxc9Ad";
var _9 = "XZ8bx6BzeQRvxOrrGNJD";
var _a = "xPZYo4MXHBdwBn5RfLTU";
var _b = "jpKwFx78ahFR7wMVaduF";
export { _1 as "body-lg", _2 as "body-md", _3 as "body-sm", _4 as "body-xs", _5 as "h1", _6 as "h2", _7 as "h3", _8 as "h4", _9 as "title-lg", _a as "title-md", _b as "title-sm" }
